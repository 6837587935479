import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import { COLOR_FONT_0, COLOR_LINK_0 } from '../colors';
import HERO_IMAGE from '../../static/images/hero.jpg';

import { LandingCenter, LandingLayout } from '../layout';

/**
 * NOTES
 * What technologies are most popular in my state/city?
 * What's the most popular programming language? Frontend framework?
 */

/**
 *
 */
const H1 = styled.h1`
  text-align: center;
  line-height: 8vw;
  font-size: 6vw;
  font-weight: 400;

  @media all and (min-width: 1024px) {
    line-height: 82px;
    font-size: 61px;
  }
`;

/**
 *
 */
const Tagline = styled.span`
  display: block;
  text-align: center;
  line-height: 5vw;
  font-size: 3vw;
  font-weight: 400;

  @media all and (min-width: 1024px) {
    line-height: 51px;
    font-size: 31px;
  }
`;

/**
 *
 */
const H3 = styled.h3`
  line-height: 48px;
  font-size: 16px;
  font-weight: 400;

  a,
  a:visited {
    color: ${COLOR_FONT_0};

    &:hover {
      color: ${COLOR_LINK_0};
    }
  }
`;

/**
 *
 */
const Hero = styled.div`
  height: 50vw;
  padding-top: 15vw;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${HERO_IMAGE}) no-repeat 0 0;
  background-size: cover;

  @media all and (min-width: 1024px) {
    height: 512px;
    padding-top: 154px;
  }
`;

/**
 *
 */
const Explore = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;

  a,
  a:visited {
    display: block;
    color: ${COLOR_FONT_0};

    &:hover {
      color: ${COLOR_LINK_0};
    }
  }

  @media all and (min-width: 768px) {
    grid-template-columns: 50% 50%;
  }

  @media all and (min-width: 1024px) {
    grid-template-columns: 25% 25% 25% 25%;
  }
`;

/**
 *
 */
export function Home(props) {
  const { categories } = props.pageContext;

  return (
    <LandingLayout>
      <Helmet>
        <title>StackTrends</title>
        <meta name="description" content="" />
      </Helmet>

      <Hero>
        <LandingCenter>
          <H1>Welcome to StackTrends</H1>
          <Tagline>Data-driven software engineering analytics.</Tagline>
        </LandingCenter>
      </Hero>

      <LandingCenter>
        <section className="home-explore">
          {categories.map((category) => (
            <div key={category.id}>
              <H3>
                <Link to={`/technologies/${category.name}/`}>{category.name_display}</Link>
              </H3>
              <Explore>
                {category.technologies.map((technology) => (
                  <Link to={`/technologies/${category.name}/${technology.name}/`} key={technology.id}>
                    {technology.name_display}
                  </Link>
                ))}
              </Explore>
            </div>
          ))}
        </section>
      </LandingCenter>
    </LandingLayout>
  );
}

export default Home;
